import {Button, Form, InputNumber, Modal, notification, Select, Space} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {SetLcdTextRequest, TextForApi} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useCallback, useEffect, useState} from 'react'

// Form props
export interface SetLcdTextFormProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const SetLcdTextForm = ({lockerId, disabled}: SetLcdTextFormProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Form initialization
  const init = useCallback((): void => {
    if (form) {
      form.resetFields()
      form.setFieldsValue({duration: 5000})
    }
  }, [form])

  // Init form
  useEffect(() => init(), [init])

  // Handle reset
  const handleReset = (): void => init()

  // Handle form submit
  const handleFinish = ({text, duration}: Store): void => {
    Modal.confirm({
      title: 'LCD text update',
      content: `The LCD text will be updated to ${text} for ${duration / 1000} seconds`,
      onOk: () => {
        setLoading(true)
        axios
          .setLcdText(new SetLcdTextRequest({lockerId, text, duration}))
          .then(res => res && setLoading(false))
          .then(() => form.resetFields())
          .then(() => form.setFieldsValue({duration: 5000}))
          .then(() => notification.success({
            message: 'LCD text was updated',
            className: 'notify-colored success',
            duration: 4
          }))
      },
    })
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish} onReset={handleReset}>
      <Form.Item
        name={'text'} required={true} label={'Text'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please select text'}]}
      >
        <Select disabled={disabled || loading} allowClear={true}>
          {Object.keys(TextForApi).map(key => (
            <Select.Option key={key} value={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={'duration'} required={true} label={'Duration'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input duration'}]}
      >
        <InputNumber disabled={disabled || loading} min={0} />
      </Form.Item>
      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button type={'primary'} danger={true} htmlType={'reset'} disabled={disabled || loading}>
            <Icon icon={'redo-alt'} />
            {'Reset'}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
