import {Button, Form, Modal, notification, Space} from 'antd'
import {AutomaticPowerOffOnResponse, LockerRequest} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useMemo, useState} from 'react'

// Form props
export interface SetAutoHardResetProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const SetAutoHardResetForm = ({lockerId, disabled}: SetAutoHardResetProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AutomaticPowerOffOnResponse | undefined>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)

  // Handle form submit
  const handleSubmit = (): void => {
    Modal.confirm({
      title: 'Hard reset in 2 minutes',
      content: 'Hard reset in 2 minutes will be set',
      onOk: () => {
        setLoading(true)
        axios
          .restartLocker(
            new LockerRequest({
              lockerId,
            })
          )
          .then(res => {
            handleLoad()
            if (res) {
              setLoading(false)
            }
          })
          .then(() =>
            notification.success({
              message: 'Hard reset in 2 minutes was set',
              className: 'notify-colored success',
              duration: 4,
            })
          )
      },
    })
  }

  const handleLoad = (): void => {
    axios.getAutomaticPowerOffOn(new LockerRequest({lockerId})).then(response => {
      setData(response)
      setIsDisabled(!response?.powerOff_Hour && !response?.powerOn_Hour && !response?.powerOff_Minute && !response.powerOn_Minute)
    })
  }

  const numberToUtcTime = (hh = 0, mm = 0): string => `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm} UTC`

  const offTime = useMemo((): string => (data ? numberToUtcTime(data.powerOff_Hour, data.powerOff_Minute) : '-'), [data])
  const onTime = useMemo((): string => (data ? numberToUtcTime(data.powerOn_Hour, data.powerOn_Minute) : '-'), [data])

  // Render JSX
  return (
    <div>
      {!isDisabled ? (
        <>
          <Form.Item name={'power_off'} label={'Power Off'} {...TinyFormItemLayout}>
            <strong>{offTime}</strong>
          </Form.Item>
          <Form.Item name={'power_on'} label={'Power On'} {...TinyFormItemLayout}>
            <strong>{onTime}</strong>
          </Form.Item>
        </>
      ) : (
        <Form.Item name={'autoreset_is_disabled'} label={'Info'} {...TinyFormItemLayout}>
          <strong>{'Hard reset is not set'}</strong>
        </Form.Item>
      )}

      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button
            type={'primary'} danger={true} htmlType={'button'} disabled={disabled || loading}
            onClick={handleLoad}
          >
            <Icon icon={'download'} />
            {'Load'}
          </Button>
          <Button type={'primary'} htmlType={'button'} disabled={disabled || loading} onClick={handleSubmit}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </div>
  )
}
