import {Button, Form, InputNumber, Modal, notification, Space} from 'antd'
import {FanTemperatureLimitsRequest, FanTemperatureSettings} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useCallback, useEffect, useState} from 'react'

// Form props
export interface FanControlFormProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const FanControlForm = ({lockerId, disabled}: FanControlFormProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Form initialization
  const init = useCallback((): void => {
    setLoading(true)
    if (form) {
      axios
        .getFanTemperatureLimits(new FanTemperatureLimitsRequest({lockerId}))
        .then(res => {
          if (res && res.result?.toLowerCase() === 'ok' && res.state && res?.state?.length > 0) {
            form.setFieldsValue(res.state[0])
          }
        })
        .finally(() => setLoading(false))
    }
  }, [form, lockerId])

  // Init form
  useEffect(() => init(), [init])

  // Handle reset
  const handleReset = (): void => init()

  // Handle form submit
  const handleFinish = (values: FanTemperatureSettings): void => {
    Modal.confirm({
      title: 'Temperature limits update',
      content: `Limits of temperatures will be updated to ${values.lowerLimit} lower and ${values.upperLimit} upper`,
      onOk: () => {
        setLoading(true)
        axios
          .setFanTemperatureLimits({
            lockerId,
            fanSetting: {...values, fanNumber: 0}
          } as FanTemperatureLimitsRequest)
          .then(init)
          .then(() => notification.success({
            message: 'Fan limits was updated',
            className: 'notify-colored success',
            duration: 4
          }))
          .finally(() => setLoading(false))
      },
    })
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish} onReset={handleReset}>
      <Form.Item
        name={'lowerLimit'} required={true} label={'Lower limit'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input lower limit'}]}
      >
        <InputNumber
          disabled={disabled || loading} min={-1000} max={1000} decimalSeparator={'.'}
          precision={1}
        />
      </Form.Item>
      <Form.Item
        name={'upperLimit'} required={true} label={'Upper limit'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input upper limit'}]}
      >
        <InputNumber
          disabled={disabled || loading} min={-1000} max={1000} decimalSeparator={'.'}
          precision={1}
        />
      </Form.Item>
      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button type={'primary'} danger={true} htmlType={'reset'} disabled={disabled || loading}>
            <Icon icon={'redo-alt'} />
            {'Reset'}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
