import {Button, Checkbox, Form, InputNumber, Modal, notification, Space} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {LockerRequest, SleepModeParametersRequest} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useCallback, useEffect, useState} from 'react'

// Form props
export interface SetKeypadProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const SetKeypadForm = ({lockerId, disabled}: SetKeypadProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Form initialization
  const init = useCallback((): void => {
    if (form) {
      form.resetFields()
      form.setFieldsValue({duration: 5000})
    }
  }, [form])

  // Init form
  useEffect(() => init(), [init])

  // Handle reset
  const handleReset = (): void => init()

  // Handle form submit
  const handleFinish = ({timeout, eraseFirstLine, eraseSecondLine, turnOffLight}: Store): void => {
    Modal.confirm({
      title: 'Keypad update',
      content: 'The keypad will be updated.',
      onOk: () => {
        setLoading(true)
        axios
          .setSleepModeParameters(new SleepModeParametersRequest({
            lockerId,
            timeout,
            eraseFirstLine,
            eraseSecondLine,
            turnOffLight
          }))
          .then(res => res && setLoading(false))
          .then(() => form.resetFields())
          .then(() => form.setFieldsValue({duration: 5000}))
          .then(() =>
            notification.success({
              message: 'Keypad was updated',
              className: 'notify-colored success',
              duration: 4,
            })
          )
      },
    })
  }

  const handleLoad = (): void => {
    axios.getSleepModeParameters(new LockerRequest({lockerId})).then(response => form.setFieldsValue(response))
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish} onReset={handleReset}>
      <Form.Item
        name={'timeout'} required={true} label={'Timeout'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input timeout'}]}
      >
        <InputNumber disabled={disabled || loading} min={0} />
      </Form.Item>
      <Form.Item
        name={'eraseFirstLine'} valuePropName={'checked'}
        label={'Erase First Line'} {...TinyFormItemLayout}
      >
        <Checkbox defaultChecked={false} />
      </Form.Item>
      <Form.Item
        name={'eraseSecondLine'} valuePropName={'checked'}
        label={'Erase Second Line'} {...TinyFormItemLayout}
      >
        <Checkbox defaultChecked={false} />
      </Form.Item>
      <Form.Item name={'turnOffLight'} valuePropName={'checked'} label={'Turn Off Light'} {...TinyFormItemLayout}>
        <Checkbox defaultChecked={false} />
      </Form.Item>
      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button
            type={'primary'} danger={true} htmlType={'button'} disabled={disabled || loading}
            onClick={handleLoad}
          >
            <Icon icon={'download'} />
            {'Load'}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
